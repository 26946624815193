<template>
  <div class="h-full cust_card p-2 mt-2" style="overflow: visible !important;">
    <div class="heading-3 text-text1">● Users List:</div>
    <div class="bottom_space rounded py-2 h-full pt-4 bg-white">
      <div class="flex items-center flex-wrap pb-2">
        
      </div>
      <div class="bottom_space flex justify-between">
          <div class="mb-2 flex">
            <div class="max-w-max mr-4">
              <div class="select_button text-sm text-text2">
              <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
              </div>
            </div>
            <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-11 mr-4">
              <span class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
              <input class="focus:outline-none w-72 text-text2" autocomplete="off" type="text" name="" placeholder="Search" id="custSearchInput"
                v-model="searchForCustomer"
                @input="getContactListDeboucne"
              >
              <span v-if="searchForCustomer.length > 0" class="cursor-pointer"><img @click="clearSearch()" height="12px" width="12px" src="@/assets/images/svg/clear-input.svg" alt="clearInput"></span>
            </div>
          </div>
        </div>
      <div>
      <div class="w-full">
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder ">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 p-1 items-center">
          <div class="name_col flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[0].isSel ? 'text-primary' : ''" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[1].isSel ? 'text-primary' : ''" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[2].isSel ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[3].isSel ? 'text-primary' : ''" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2 ">
            <span class="cursor-pointer" :class="titleObj[4].isSel ? 'text-primary' : ''" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell2_col  flex items-center p-2 justify-center">
            <span class="">{{titleObj[5].title}}</span>
          </div>
          <div class="cell2_col  flex items-center p-2 justify-center">
            <span class="">{{titleObj[6].title}}</span>
          </div>
        </div>
        <div v-for="(data, index) in organizationList" :key="index" class="min-w-min group flex border-r border-l border-b relative border-gray1  bg-white  text-sm p-1 items-center  hover:bg-gray-50" >
          <div class="name_col p-2">
            <span class="heading-4 text-text2 " v-if="data.firstName !== '' || data.lastName !== ''">{{data.firstName}} {{data.lastName}}</span>
            <span class="" v-else>--</span>
            <div class="text-gray4">({{getStatus(data)}})</div>
          </div>
          <div class="name_col p-2" >
            <span class="heading-4 text-primary cursor-pointer" v-if="data.organizationName !== ''" @click="$router.push({name: 'OrganizationDetail', params: {orgId: data.organizationId}})">{{data.organizationName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2">
            <span class="heading-4 text-text2" v-if="data.emailAddress !== ''">{{data.emailAddress}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2">
            <span class="heading-4 text-text2" v-if="data.cellNumber !== ''">{{data.cellNumberPrefix}} {{data.cellNumber | phone}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2">
            <span class="heading-4 text-text2" v-if="data.registeredOn !== ''">{{data.registeredOn | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell2_col p-2 text-center">
            <span class="heading-5" v-if="data.isCellVerified"><i class="fas fa-check text-success"></i></span>
            <span class="heading-5" v-if="!data.isCellVerified"><i class="fas fa-times text-error"></i></span>
          </div>
          <div class="cell2_col p-2 text-center">
            <span class="heading-5" v-if="data.isEmailVerified"><i class="fas fa-check text-success"></i></span>
            <span class="heading-5" v-if="!data.isEmailVerified"><i class="fas fa-times text-error"></i></span>
          </div>
          <div class="absolute right-4" v-if="data.isAdmin || data.isOwner">
            <span class="heading-2 cursor-pointer" @click.stop="redirctUsingUser(data)"><i class="fa-solid fa-diamond-turn-right text-primary"></i></span>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="organizationList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalEmpCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
  <div v-if="resetPasswrod" class="popup_overlay px-4">
    <div class="custom_dialog rounded-lg" style="width: 600px;max-height: 95%;">
      <div class="relative bg-white text-center">
        <div class="" v-if="!otpSended">
          <div class="bg-primary flex justify-between items-center p-3">
            <div class=" font-medium text-white heading-3">
              Please verified your identity
            </div>
          </div>
          <div class="p-2">
            <div class="heading-5 text-gray4 pt-2">Select one of the options where you want to send the verification mail.</div>
            <div class="flex items-center justify-center py-4">
              <button :class="!isSuperOwnerSend && isSuperAdminSend ? 'bg-success border-success' : 'bg-primary border-gray4'" @click="changeButtonEmail(1)" class="mx-1 px-2 py-2 rounded-lg border text-white font-semibold heading-6"><span v-if="!isSuperOwnerSend && isSuperAdminSend"><i class="fas fa-check-circle heading-4 text-white pr-2"></i></span>Send Email To Super Admin</button>
              <button :class="isSuperOwnerSend && !isSuperAdminSend ? 'bg-success border-success' : 'bg-primary border-gray4'" @click="changeButtonEmail(2)" class="mx-1 px-2 py-2 rounded-lg border text-white font-semibold heading-6"><span v-if="isSuperOwnerSend && !isSuperAdminSend"><i class="fas fa-check-circle heading-4 text-white pr-2"></i></span>Send Email To Owner</button>
            </div>
            <div class=" text-text2 text-center my-2 mt-4">
              <span v-if="IssendEmail" class=" cursor-pointer font-bold bg-primary text-white rounded-lg px-2 py-2 mr-2" @click="sendEmailApi()">Send Email</span>
              <span class=" cursor-pointer font-bold border border-gray4 rounded-lg px-2 py-2" @click="canccleClick()">Cancel</span>
            </div>
          </div>
        </div>
      <div class="p-2 mt-4" v-if="otpSended">
        <p class="heading-4 text-text2 font-semibold">Please enter OTP sent to {{!isSuperOwnerSend && isSuperAdminSend ? 'Super Admin' : 'Owner'}} Email</p>
        <div class=" my-3 flex justify-center">
          <div class="w-56 heading-4">
            <TextField
            :isOtp="true"
            :textMaxlength="6"
            :inputId="`otpinputvalueid`"
            :inputext="otpValue"
            :placholderText="``"
            :lableText="''"
            :autoFocus="true"
            :inputType="'text'"
            @inputChangeAction="(data) => otpValue = data"  />
          </div>
        </div>
        <div class="flex justify-end">
          <div v-if="countDown > 0" class="pr-4 text-text1">Resend OTP  <span >in {{countDown}} Sec</span></div>
          <div v-else @click="reSendOTP" class=" cursor-pointer text-primary pr-4">Resend OTP</div>
        </div>
        <div class="p-4">
          <div class="py-1.5 mb-px">
            <button :disabled="otpValue.length <= 5"
              :class="otpValue.length <= 5 ? 'opacity-30 cursor-not-allowed' : ' '"
              class="w-full heading-5 font-semibold text-white bg-primary2 rounded-full py-2"
              large
              @click="resetPasword1">Verify
            </button>
          </div>
          <div class=" text-text2 text-center mt-2">
            <span class=" cursor-pointer font-bold" @click="canccleClick();resetPasswrod=false;otpSended=false">Cancel</span>
          </div>
        </div>
        </div>
      </div>
    </div>
</div>
</div>
</template>
<script>
import TextField from '@/View/components/textfield.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
// import Pagination from '@/View/components/pagination.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Pagination,
    TextField,
    SimpleDropdown,
  },
  mixins: [deboucneMixin],
  props: ['orgId'],
  data () {
    return {
      countDown: 30,
      otpAuthToken: '',
      isSuperAdminSend: false,
      isSuperOwnerSend: false,
      otpValue: '',
      otpSended: false,
      IssendEmail: false,
      resetPasswrod: false,
      selectedUser: null,
      projectURL: '',
      showInActive: true,
      mobileView: false,
      searchLoader: false,
      resetPaginationValues: false,
      searchForCustomer: '',
      titleObj: [
        {title: 'User Name', icon: '', sortName: 'firstName', sortVal: '', isSel: false},
        {title: 'Organization Name', icon: '', sortName: 'organizationName', sortVal: '', isSel: false},
        {title: 'Email', icon: '', sortName: 'emailAddress', sortVal: '', isSel: false},
        {title: 'Cell', icon: '', sortName: 'cellNumber', sortVal: '', isSel: false},
        {title: 'Created Date', icon: 'fas fa-sort-amount-down', sortName: 'registeredOn', sortVal: 'DESC', isSel: true},
        {title: 'Is Call Verified', icon: '', sortName: '', sortVal: '', isSel: false},
        {title: 'Is Email Verified', icon: '', sortName: '', sortVal: '', isSel: false},
    ],
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'registeredOn',
        sortOrder: 'DESC',
        isActive: true,
        organizationId: 0,
      },
      totalEmpCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      organizationList: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler();
    document.title = 'User List'
    this.getContactListDeboucne = this.debounce(function () {
      if (this.searchForCustomer.length > 2) {
        this.resetPaginationValues = true
        this.searchLoader = true
      }
      console.log('debounde')
    }, 500)
    this.getEmployeeList()
  },
  watch: {
    searchForCustomer: {
      handler () {
        if (this.searchForCustomer === null) {
          this.searchForCustomer = ''
        }
        if (this.searchForCustomer === '') {
          this.resetPaginationValues = true
          this.getEmployeeList()
        }
      }
    },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getEmployeeList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    resetPasword1 () {
      if (!this.isSuperAdminSend && this.isSuperOwnerSend) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        ADMINAPI.VarifiedOTPToOwner(
          this.otpAuthToken,
          this.otpValue,
          response => {
            clearTimeout(this.countDownTimerId);
            this.resetPasswrod = false
            this.otpSended = false
            this.canccleClick()
            let userDetail = JSON.parse(localStorage.getItem('employeeData1'))
            let name = userDetail.firstName + ' ' + userDetail.lastName
            // window.open('http://10.0.0.25:8080/' + 'login?uid=' + this.selectedUser.userDetailId + '&user=' + name , '_blank');
            window.open(this.projectURL + 'login?uid=' + this.selectedUser.userDetailId + '&user=' + name, '_blank');
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.isSuperAdminSend && !this.isSuperOwnerSend) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        ADMINAPI.VarifiedOTPToSAdmin(
          this.otpAuthToken,
          this.otpValue,
          response => {
            clearTimeout(this.countDownTimerId);
            this.resetPasswrod = false
            this.otpSended = false
            this.canccleClick()
            let userDetail = JSON.parse(localStorage.getItem('employeeData1'))
            let name = userDetail.firstName + ' ' + userDetail.lastName
            // window.open('http://10.0.0.25:8080/' + 'login?uid=' + this.selectedUser.userDetailId + '&user=' + name , '_blank');
            window.open(this.projectURL + 'login?uid=' + this.selectedUser.userDetailId + '&user=' + name, '_blank');
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    canccleClick () {
      this.isSuperOwnerSend = false
      this.isSuperAdminSend = false
      this.IssendEmail = false
      this.otpAuthToken = ''
      this.resetPasswrod = false
      clearTimeout(this.countDownTimerId);
    },
    getStatus(data) {
      if (data.isAdmin && data.isOwner) {
        return 'Admin / Owner';
      } else if (data.isAdmin && !data.isOwner) {
        return 'Admin';
      } else if (!data.isAdmin && data.isOwner) {
        return 'Owner';
      } else {
        return 'Non Admin'; // Optional, in case neither is true
      }
    },
    changeButtonEmail (from) {
      if (from === 1) {
        this.isSuperOwnerSend = false
        this.isSuperAdminSend = true
      } else {
        this.isSuperOwnerSend = true
        this.isSuperAdminSend = false
      }
      this.IssendEmail = true
    },
    redirctUsingUser (data) {
      this.selectedUser = data
      this.resetPasswrod = true
      // let userDetail = JSON.parse(localStorage.getItem('employeeData1'))
      // let name = userDetail.firstName + ' ' + userDetail.lastName
      // // window.open('http://10.0.0.25:8081/' + 'login?uid=' + data.userDetailId + '&user=' + name , '_blank');
      // window.open(this.projectURL + 'login?uid=' + data.userDetailId + '&user=' + name, '_blank');
    },
    addNewComp () {
      this.$router.push({name: 'AddNewCompetition'})
    },
    sendEmailApi () {
      if (!this.isSuperAdminSend && this.isSuperOwnerSend) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Sending Email...'})
        ADMINAPI.SendOTPToOwner(
          this.selectedUser.organizationId,
          this.selectedUser.userDetailId,
          response => {
            this.otpAuthToken = response.Data
            this.countDown = 30
            this.countDownTimer()
            this.resetPasswrod = true
            this.otpSended = true
            console.log("response", response.Data)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.isSuperAdminSend && !this.isSuperOwnerSend) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Sending Email...'})
        ADMINAPI.SendOTPToSAdmin(
          this.selectedUser.organizationId,
          this.selectedUser.userDetailId,
          response => {
            this.otpAuthToken = response.Data
            this.otpSended = true
            this.countDown = 30
            this.countDownTimer()
            this.resetPasswrod = true
            console.log("response", response.Data)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    reSendOTP () {
      if (!this.isSuperAdminSend && this.isSuperOwnerSend) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Resending Email...'})
        ADMINAPI.ReSendOTPToOwner(
          this.otpAuthToken,
          this.selectedUser.organizationId,
          this.selectedUser.userDetailId,
          response => {
            this.countDown = 30
            this.otpValue = ''
            this.countDownTimer()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.isSuperAdminSend && !this.isSuperOwnerSend) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Resending Email...'})
        ADMINAPI.ReSendOTPToSAdmin(
          this.otpAuthToken,
          this.selectedUser.organizationId,
          this.selectedUser.userDetailId,
          response => {
            this.countDown = 30
            this.countDownTimer()
            this.otpValue = ''
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.countDownTimerId = setTimeout(() => { // Store the timer ID
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    clearSearch () {
      this.searchForCustomer = ''
      this.searchLoader = false
      this.getEmployeeList()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getEmployeeList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          console.log('this.titleObj[i].icon', this.titleObj[i].icon)
          console.log('this.titleObj[i].sortVal', this.titleObj[i].sortVal)
          console.log('this.filterObject.sortOrder', this.filterObject.sortOrder)
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].isSel = true
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
            this.titleObj[i].isSel = true
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
            this.titleObj[i].isSel = true
          }
      } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
          this.titleObj[i].isSel = false
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getEmployeeList(true)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    routingToDetail (data) {
      this.$router.push({name: 'OrganizationDetail', params: {orgId: data.organizationId}})
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getEmployeeList()
    },
    getEmployeeList () {
      console.log('this.routing', this.orgId)
      if (this.orgId === undefined) {
        this.filterObject.organizationId = 0
      } else {
        this.filterObject.organizationId = this.orgId
      }
      if (this.searchForCustomer === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      ADMINAPI.getUserList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForCustomer,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.isActive,
        this.filterObject.organizationId,
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          this.organizationList = response.Data.tableRow === null ? [] : response.Data.tableRow
          console.log('this.organizationList', this.organizationList)
          this.totalEmpCount = response.Data.count
          this.projectURL = response.Data.requiredData
          console.log('count', this.totalEmpCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell2_col {
  min-width: 180px !important;
  width: 180px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 210px !important;
  width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.price_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #307dea;
  width: 27px;
  height: 27px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
